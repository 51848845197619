<template>
    <el-dialog :title="'Modifica semnatura - ' + selectedObject.Nume" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" label-width="70%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                       
                <el-col >
                    <el-form-item label='Semnatura' >
                        <VueSignaturePad width="400px" height="200px" :options="{ penColor: 'rgb(58,132,210)'}" class="border-signature" ref="signaturePad" />
                    </el-form-item>                    
                </el-col>     

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"> Renunta  </el-button>
            <el-button type="warning" plain @click="undo" >Corecteaza semnatura</el-button>            
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BaseComponent from '@/pages/BaseComponent';



    export default {
        name: "upload-semnatura",
        extends: BaseComponent,
        data () {
            return {
                showPopup: false,
                selectedObject: { Nume: '', Semnatura: null },
                loadingVisible: true
            }
        },
        methods: {
            get_info: async function(){
                this.showPopup      = true;
                this.loadingVisible = true;
                var response        = await this.post("Setari/get_firma");
                this.selectedObject = response.Firma;      
                this.$refs.signaturePad.fromDataURL(this.selectedObject.Semnatura)
                this.loadingVisible = false
            },
            save: async function() {
                const { isEmpty, data }         = this.$refs.signaturePad.saveSignature()                
                this.selectedObject.Semnatura   = data
                await this.post("Setari/save_semnatura_firma", { object: this.selectedObject } );
                this.showPopup = false;
                this.$refs.signaturePad.undoSignature();
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            }
        },
        mounted: function() {
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .border-signature {
      border:2px solid #CECECE;
    }

</style>