<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Setari
    el-card
      el-form(label-position="top" :inline="false" label-width="100%"  :model='Fields'  ref='my-form' )
        el-row(:gutter="20")
          el-col(:span='6')
            el-form-item( label="Email zile de nastere" prop="EmailZileDeNastere"  )
              el-input( v-model="Fields.Email.Valoare"  type="email")
            p( class="message") {{Results.message}}
          el-col(:span='6')
            el-form-item( label="Trimit email saptamanal cu zilele de nastere" prop="EmailAutomat"  )
              el-checkbox(  v-model="Fields.EmailSaptamanal.Valoare" true-label="1" false-label="0" class="emailCheckbox") Da/Nu
        el-row(:gutter="20")
          el-col(:span='6')
            el-form-item( label="Email gestionare concedii (emailul la care se trimit cererile de concediu pentru a fi aprobate/respinse)" prop="EmailGestionareConcedii"  )
              el-input( v-model="Fields.EmailGestionareConcedii.Valoare"  type="email")
            p( class="message") {{Results.message}}
        el-row(:gutter="20")
          el-col(:span='6')
            el-form-item( label="Email contabilitate (emailul la care se trimit cererile de concediu dupa ce au fost aprobate)" prop="EmailContabilitate"  )
              el-input( v-model="Fields.EmailContabilitate.Valoare"  type="email")
            p( class="message") {{Results.message}}
        el-row(:gutter="20")
          el-col(:span='6')
            el-form-item( label="Email condica" prop="EmailCondica"  )
              el-input( v-model="Fields.EmailCondica.Valoare"  type="email")
            p( class="message") {{Results.message}}
          el-col(:span='4')
            el-form-item( label="Trimit email zilnic condica" prop="EmailAutomatCondica"  )
              el-checkbox(  v-model="Fields.EmailAutomatCondica.Valoare" true-label="1" false-label="0" class="emailCheckbox") Da/Nu
          el-col(:span='6')
            el-form-item( label="Ora trimitere email" prop="OraEmailCondica"  )
              el-time-select( v-model="Fields.OraEmailCondica.Valoare" :picker-options="{start: '00:15', end: '23:45', step: '00:15'}" placeholder="Select time")
        el-row(:gutter="20")
          el-col(:span='20')
            el-button( type="warning" size='' icon="el-icon-s-check" @click="semneaza()" ) Semnatura
      div.top50
        el-button(type='success' @click="save") Salveaza
    dialog-semnatura( ref='semnatura' @refresh="refresh_info()" )
      
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import AngajatiDialog from '../dialogs/AngajatiDialog';
import ImportAngajatiDialog from '../dialogs/ImportAngajatiDialog';
import SemnaturaDialog from '../dialogs/UploadSemnaturaDialog';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "angajati",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'titlu-pagina': TitluPagina,
      'dialog-semnatura': SemnaturaDialog,
  },
  data () {
    return { 
      Fields: {Email:{Cheie: 'email_zile_de_nastere', Valoare: ''}, EmailSaptamanal:{Cheie: 'email_saptamanal', Valoare: false}, EmailContabilitate: {Cheie: 'email_contabilitate', Valoare: ''}, EmailGestionareConcedii: {Cheie: 'email_gestionare_concedii', Valoare: ''}, EmailCondica: {Cheie: 'email_condica', Valoare: ''}, EmailAutomatCondica: {Cheie: 'email_automat_condica', Valoare: false}, OraEmailCondica: {Cheie: 'ora_email_condica', Valoare: ''} },
      Results: {NotLogged: '', message: ''},
    }
  },
  methods: {
    async get_info(){
      var        results = await this.post("setari-get", {} );
      console.log(results);
      this.Fields.Email.Valoare = results['email_zile_de_nastere'];
      this.Fields.EmailSaptamanal.Valoare = results['email_saptamanal'];

      this.Fields.EmailContabilitate.Valoare = results['email_contabilitate'];
      this.Fields.EmailGestionareConcedii.Valoare = results['email_gestionare_concedii'];

      this.Fields.EmailCondica.Valoare = results['email_condica'];
      this.Fields.EmailAutomatCondica.Valoare = results['email_automat_condica'];
      this.Fields.OraEmailCondica.Valoare = results['ora_email_condica'];
    },
    semneaza() {
        this.$refs.semnatura.get_info();
    },
    async save(){
      var response         = await this.post("setari-save", { Fields: this.Fields } );
      this.Results.message = response['message'];
      this.get_info();
    },
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    this.base_url = settings.get_base_url();
    this.get_info();
  }
};
</script>

<style lang="less" scoped>

.message{
    color: red;
    font-size: 12px;
    margin-top: -15px;
}

  .top50{
    margin-top: 20px;

  }

.emailCheckbox{
    margin-left: 20px;
}
  
 
</style>
