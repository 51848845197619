<template lang="pug">
    el-dialog( title="Importare din excel" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :span='24' )
                    strong Coloane obligatorii: 
                    p Nume | Tag | Telefon | Centru de cost | Departament | Program | Data angajarii | Observatii
                el-col( :span="24" )
                    el-form-item( label="Copy paste din excel" prop="content"  :required="true" )
                        el-input( type='textarea' v-model="selectedObject.content"  :rows="10")

               
        span( slot="footer" class="dialog-footer" )
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "import-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      selectedObject:{
        content:''
      },
      rules: {
          content: [ { required: true, message: "Continutul este obligatoriu" } ],
      }
    }
  },
  methods: {
     show_me: async function(  ) {
         this.showPopup                 = true;
         this.selectedObject.content    = '';
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            await this.post("angajati/import_angajati", { content: this.selectedObject.content } );
            this.showPopup = false;
            this.$emit("refresh");
          }
        });
    }
  },
  mounted: function() {
      
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>